const head = process.env.VUE_APP_BASE_API
const aouth = process.env.VUE_APP_BASE_Base
const user2 = process.env.VUE_APP_BASE_User2
const exam = process.env.VUE_APP_BASE_Exam
const course1 = process.env.VUE_APP_BASE_Course1
const user = process.env.VUE_APP_BASE_User
const live = process.env.VUE_APP_BASE_Live
const oauth = process.env.VUE_APP_BASE_Oauth
const sysSys = process.env.VUR_APP_BASE_USERSYS
// const user = process.env.VUE_APP_BASE_API_USER
localStorage.setItem('head', head)
const api = {}
const aouthApi = {
	noticeApi: 'userNotice/getByPage', //分页查询通知公告
	getServiceInfo: 'userWebsite/getServiceInfo', //获取机构的客服信息
	userWebsite: 'userWebsite/getByPage', //机构网页信息展示数据
	querySysBottom: 'userBottom/querySysBottom', //机构网页底部数据
	downloadTemplate: 'userWebsite/downloadTemplate', //下载模板
	getCollective: 'userWebsite/getCollective', //获取集体报名步骤
	getMenuList: 'userMenu/queryMenu', //获取站点导航栏
	getNavColor: 'userMenuColor/selectSysMenuColorByOrgId', //获取导航栏颜色
	getChildWebSite: 'userWebsite/getSysWebSiteInfo', //获取子站列表
	getProtocol: 'UserProtocolConfiguration/selectOne', //获取站点服务协议
}

const oauthApi = {
	getCodeApi: 'stuSys/getCode', //获取验证码
	loginApi: 'stuSys/login', //登录
	loginOutApi: 'stuSys/loginOut', //登出
	getPowerAssign: 'stuSys/getPowerAssign', //获取注册信息
	retrievePassword: 'stuSys/retrievePassword', //找回密码步骤一
	registerApi: 'stuSys/register', //注册
	registerGetCode: 'stuSys/registerGetCode', //注册获取手机验证码

}

const user2Api = {

}

const userApi = {
	teacherQuery: 'userSys/queryPage', //分页查询权威教师
	teachersGetByPage: 'userSys/queryPage', // 师资权威
	upLoadPhoto: '/userSys/upLoadPhoto', // 上传头像byOrderCode
	updataPassword: 'userSys/updataPassword', // 修改密码
	updataInfo: 'userSys/updataInfo', // 编辑个人资料
	individualOrders: 'userSys/individualOrders', // 个人订单
	myLivesApi: 'userSys/myLives', // 我的直播
	myTrainApi: 'userSys/myTrain', // 我的培训
	myInvoiceApi: 'userSys/allowInvoicePage', // 允许开票分页
	myCertificateApi: 'userSys/myCertificate', // 我的证书
	getCollectiveQrCode: 'userPay/getCollectiveQrCode', // 集体报名支付，
	myInvoice: 'userSys/myInvoice', // 开票历史
	applyForInvoice: 'userPay/applyForInvoice', // 申请开票
	queryLoginPhoto: 'userSys/queryLoginPhoto', // 获取头像
	refund: "userPay/refund", //申请退款
	cancellationOrder: "userPay/cancellationOrder", // 取消订单
	getName: "userPay/getName", // 获取用户登录的名称
	wxById: 'order/ById',
	byOrderCode: 'order/byOrderCode', // 工具订单id获取订单
	getPayType: 'org/queryStuPayType', //获取站点支付方式
	catRegisterGetCode: 'userSys/registerGetCode', //切换绑定手机号码 获取验证码
	catPhoneNumber: 'userSys/catPhoneNumber', //切换绑定手机号码
	identityGetCode: 'userSys/identityGetCode', //切换绑定身份证 获取验证码
	identityUpdate: 'userSys/identityUpdate', //切换绑定身份证
	getMyTrainYears: 'userSys/getYears', //获取我的培训年份
	destroyAccount: 'userSys/getDestroy', // 注销账号
}

const examApi = {
	applyExam: 'api/paper/apply', //申请考试
	paperStart: 'api/paper/start', //开始考试/获取试卷
	handPaper: 'api/paper/hand', //交卷
	paperItem: 'api/paper/item', //查看试卷
	errorMineApi: 'error/mine', //错题库
	delErrorApi: 'error/del', //删除错题
	mineTest: 'test/mine', //课后练习
	mineSave: 'test/save', //保存课后练习
}
const course1Api = {
	getClassifyByOrgIdAndYear:'userCourse/getClassifyByOrgIdAndYear',//根据机构id和年份获取二三级课程分类
	getYearsByClassifyOne:'userCourse/getYearsByClassifyOne',//根据一级分类查询年份信息
	course: 'userCourse/getOrgClassify', //课程分类
	getOrgCourse: 'userCourse/getOrgCourse', //课程信息
	getCourseYears: 'userCourse/getYears', //查询年份
	getOrgCourseYears: 'userCourse/getOrgYears', //查询年份
	getCourseInfoById: 'userCourse/getCourseInfoById', //根据课程id获取课程详细信息
	getCourseSectionById: 'userCourse/getCourseSectionById', //章节信息
	addComment: 'userCourse/addComment', //添加评论
	getCourseCommentById: 'userCourse/getCourseCommentById', //获取评论
	studyProgressApi: 'userCourse/studyProgress', //获取评论
	studyCourseApi: 'userCourse/studyCourse', //学习课程
	peixunAdd: 'userCourse/add', //发布学习心得
	changeCourse: 'userChangeCourse/apply', //更换课程
}
const liveApi = {
	liveGetByPage: 'userLive/getByPage', //直播课堂
	onLive: 'userLive/onLive', //进入直播间
	userLiveAdd: 'userLive/add', //发送弹幕
	userLiveGetList: 'userLive/getList', //弹幕列表
	liveById: 'live/getById' // 获取直播数据的详细数据
}
// 支付
const pay = {
	payMoney: 'userPay/placeOrderNew', //立即购买
	getOrderByid: 'order/byOrderCode' // 根据订单编号获取数据
	// individualOrders: "/userSys/individualOrders" //个人订单渲染开票
}
const touxImg = {
	// queryLoginPhoto: 'userSys/queryLoginPhoto', // 获取头像
	// refund: "userPay/refund", //申请退款
	// cancellationOrder:"/userPay/cancellationOrder", // 取消订单
	// getName: "userPay/getName" // 获取用户登录的名称
}
for (const key in aouthApi) {
	api[key] = head + aouth + aouthApi[key]
}
for (const key in oauthApi) {
	api[key] = head + oauth + oauthApi[key]
}
for (const key in user2Api) {
	api[key] = head + user2 + user2Api[key]
}
for (const key in examApi) {
	api[key] = head + exam + examApi[key]
}
for (const key in course1Api) {
	api[key] = head + course1 + course1Api[key]
}
for (const key in userApi) {
	api[key] = head + user + userApi[key]
}
for (const key in liveApi) {
	api[key] = head + live + liveApi[key]
}
for (const key in pay) {
	api[key] = head + user + pay[key]
}
for (const key in touxImg) {
	api[key] = head + touxImg[key]
}
export default api
