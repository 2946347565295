<template>
  <div>
    <div class="editInfoContent">
      <el-tabs v-model="activeName">
        <el-tab-pane :model="addForm" label="基本信息" name="first">
          <el-form style="padding-left:30px;">
            <el-form-item label="课程数量:">
              <el-radio-group v-model="addForm.number">
                <el-radio label="基本满意">基本满意</el-radio>
                <el-radio label="满意">满意</el-radio>
                <el-radio label="非常满意">非常满意</el-radio>
                <el-radio label="不满意">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="课程质量:">
              <el-radio-group v-model="addForm.quality">
                <el-radio label="基本满意">基本满意</el-radio>
                <el-radio label="满意">满意</el-radio>
                <el-radio label="非常满意">非常满意</el-radio>
                <el-radio label="不满意">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="学习的效果:">
              <el-radio-group v-model="addForm.effect">
                <el-radio label="基本满意">基本满意</el-radio>
                <el-radio label="满意">满意</el-radio>
                <el-radio label="非常满意">非常满意</el-radio>
                <el-radio label="不满意">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="视频清淅度:">
              <el-radio-group v-model="addForm.cxd">
                <el-radio label="基本满意">基本满意</el-radio>
                <el-radio label="满意">满意</el-radio>
                <el-radio label="非常满意">非常满意</el-radio>
                <el-radio label="不满意">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="网站速度:">
              <el-radio-group v-model="addForm.speed">
                <el-radio label="基本满意" size="large">基本满意</el-radio>
                <el-radio label="满意" size="large">满意</el-radio>
                <el-radio label="非常满意" size="large">非常满意</el-radio>
                <el-radio label="不满意" size="large">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="售后服务:">
              <el-radio-group v-model="addForm.service">
                <el-radio label="基本满意" size="large">基本满意</el-radio>
                <el-radio label="满意" size="large">满意</el-radio>
                <el-radio label="非常满意" size="large">非常满意</el-radio>
                <el-radio label="不满意" size="large">不满意</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div style="padding-left:30px;">
            <el-button type="primary" @click="submit">提交</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios'

export default {
  name: 'PaperCheck',
  data() {
    return {
      activeName: "first",
      addForm: {}
    }
  },

  computed: {
    ...mapGetters(['doneUserInfo']),
  },

  methods: {
    async submit() {
      // 创建 FormData 实例
      const formData = new FormData();
      let key;
      console.log(this.addForm);
      for (key in this.addForm) {
        formData.append(key, this.addForm[key]);
      }
      formData.append("userName", this.doneUserInfo.name);

      let res = await axios.post("https://apitest.jjyxt.cn/user/sysStatistics/addQuestionnaire", formData, {
        headers: {
          // 重要：设置 Content-Type 为 multipart/form-data
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log(res);

      if (res.data.code == 200) {
        this.$message.success("提交成功！");
        this.addForm = {};
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.editInfoContent {
  width: 974px;
  margin-bottom: 100px;
  padding-bottom: 50px;
  background-color: #fff;

  /deep/ .el-tabs__item {
    font-size: 24px;
    padding-bottom: 19px;
    padding-left: 21px !important;
    line-height: 63px;
    height: 63px;
  }

  /deep/ .el-tabs__nav-scroll {
    height: 63px;
  }

  /deep/ .el-tabs__active-bar {
    // left: 24px;
    background-color: #39AEFF;
  }

  /deep/ .el-tabs__item:hover {
    color: #39AEFF;
  }

  /deep/.el-tabs__item.is-active {
    color: #39AEFF;
  }

}
</style>