<template>
	<div class="pageContainer">
		<web-header></web-header>

		<!-- <div class="main"> -->

		<!-- </div> -->
		<div class="info main">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div class="content " v-if="courseLevel1List.length > 0">
				<!-- 第一级 -->
				<div class="course_level_one" v-if="courseLevel1List.length > 0">
					<div class="item"><span>培训项目</span></div>
					<div v-for="(item,index) in courseLevel1List" :key="index"
						:class="['item',{'active':index === oneIndex}]" @click="handleLevel1Change(index,item.id)">
						<span>{{item.name}}</span>
						<!-- <i :class="index === oneIndex ? 'el-icon-arrow-up':'el-icon-arrow-down'"></i> -->
					</div>
				</div>
				<!-- 年份 -->
				<div class="years" v-if="yearsList.length > 0">
					<div class="item">
						<span>所属年份</span>
					</div>
					<div v-for="(item,index) in yearsList" :key="index" :class="['item',{'active':index === yearIndex}]"
						@click="handleYearChange(index)">
						<span>{{item}}</span>
					</div>
				</div>
				<!-- 第二级 -->
				<div class="course_level_two" v-if="courseLevel2List.length > 0">
					<div v-for="(item,index) in courseLevel2List" :key="index"
						:class="['item',{'active':index === twoIndex}]" @click="handleLevel2Change(index)">
						<span>{{item.name}}</span>
					</div>
				</div>
				<!-- 第三级 -->
				<div class="course_level_three" v-if="courseLevel3List.length > 0">
					<div v-for="(item,index) in courseLevel3List" :key="index"
						:class="['item',{'active':index === threeIndex}]" @click="handleLevel3Change(index)">
						<span>{{item.name}}</span>
					</div>
				</div>
				

				<div v-if="courseList.length > 0" class="contentCenter animate__animated animate__fadeInLeft">
					<div class="cardCls" v-for="it in courseList" :key="it.id">
						<img @click="toCourseDetail(it)" :src="it.coverPicture" alt="">
						<div>
							<h2 class="teacherName">
								{{it.name}}
							</h2>
								<p class="cost">￥{{it.cost}}</p>
							<p class="teacherMsg">教师信息：{{it.teacherName}}</p>
							<p v-if="it.typeId == 1">学时：{{it.classHours}}个学时</p>
							<p v-else>学分：{{it.classHours}}个学分</p>
						</div>
						<div class="cardBottom">
							<div @click="toViewTest(it)" v-if="it.isPay === 0&&it.studyEndTime>nowdate">试看课程</div>
							<div @click="toViewGoon(it)" v-else-if="it.isPay === 1&&it.studyEndTime>nowdate">
								{{it.studyCchedule&&it.studyCchedule > 0? '继续学习' : '开始学习'}}
							</div>
							<div v-if="it.isPay === 0&&it.studyEndTime>nowdate" @click="toPayEvt(it)">购买课程</div>
							<div v-if="it.isPay === 1&&it.studyEndTime>nowdate" @click="courseEvt(it)"
								style="cursor: pointer;">已购买</div>
							<div v-else-if="it.studyEndTime<nowdate" @click="end" id="end">已结束</div>
						</div>
					</div>
				</div>
				<!-- <div class="noTeacter" v-else-if="courseList.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，暂时没有您想要的内容</div>
					</div>
				</div> -->
				<div v-if="courseList.length > 0">
					<el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="total"
						@current-change="handleCurrentChange">
					</el-pagination>
				</div>
			</div>
			<!-- <div class="noTeacter" v-else-if="courseLevel1List.length === 0">
				<div class="noTeacter_img">
					<img src="../../assets/imgs/4.png" alt="">
					<div>抱歉，暂时没有您想要的内容</div>
				</div>
			</div> -->
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "培训项目"],
				oneIndex: 0,
				twoIndex: 0,
				threeIndex: 0,
				yearIndex: 0,
				index: 0,
				show: false,
				courseLevel1List: [],
				courseLevel2List: [],
				courseLevel3List: [],
				yearsList: [],
				yearVal: '',
				courseList: [],
				levelId: '',
				total: 0,
				pageSize: 8,
				pageNumber: 1,
				courseId: '',
				nowdate: ''
			}
		},
		created() {
			//获取当前时间
			this.getNowFormatDate()
			// 获取第一级分类
			this._getFirstLevelClass()
		},
		computed: {
			...mapState(['isLogin'])
		},
		methods: {
			// 第一级切换
			handleLevel1Change(i,id) {
				this.oneIndex = i;
				this.twoIndex = 0;
				this.threeIndex = 0;
				this.yearIndex = 0;
				this.yearVal = '';
				this.pageNumber = 1;
				this.getOrgCourseYears(id)
			},
			// 第二级切换
			handleLevel2Change(i) {
				this.twoIndex = i;
				this.threeIndex = 0;
				// this.yearIndex = 0;
				// this.yearVal = '';
				this.pageNumber = 1;
				this._getLastLevelClass('three')
			},
			// 第三级
			handleLevel3Change(i) {
				// this.yearIndex = 0;
				// this.yearVal = '';
				this.threeIndex = i;
				this.pageNumber = 1;
				this.courseId = this.courseLevel3List[i].id || this.courseLevel2List[this.twoIndex].id;
				// this.getOrgCourseYears()
				this.getList()
			},
			// 年份切换
			handleYearChange(i) {
				this.yearIndex = i;
				this.yearVal = this.yearsList[i]
				this._getLastLevelClass('two')
				// this.getList()
			},
			//去试看课程
			toViewTest(it) {
				if (this.isLogin) {
					//获取章节信息
					this.$http.post('getCourseSectionById', {
						id: it.id
					}).then(res => {
						if (res.data.length > 0 && res.code === 200) {
							this.$router.push({
								path: '/trainingProgram/courseDetails',
								query: {
									id: it.id ? it.id : '',
									sectionId: res.data[0].id ? res.data[0].id : '',
									status: it.isPay,
									data: it
								}
							})
						} else {
							this.$message.error('当前课程没有试看章节')
						}
					})
				} else {
					this.$message.error('请登录')
				}

			},
			//跳转课程详情页面
			courseEvt(it) {
				if (this.isLogin) {
					this.$router.push({
						path: '/trainingProgram/courseDetails',
						query: {
							id: it.id,
							index: 3
						}
					})
				} else {
					this.$message.error('请先登录')
				}

			},
			//去继续学习视频页面
			toViewGoon(it) {
				if (this.isLogin) {
					//获取章节信息
					this.$http.post('getCourseSectionById', {
						id: it.id
					}).then(res => {
						if (res.code === 200) {
							if (res.data.length > 0) {
								let sectionId = res.data[0].id
								this.$http.post('studyCourseApi', {
									courseId: it.id,
									sectionId: sectionId
								}).then(res => {
									if (res.code === 200) {
										this.$router.push({
											path: '/trainingProgram/courseDetails',
											query: {
												id: it.id ? it.id : '',
												sectionId: sectionId ? sectionId : '',
												status: 2,
												time: res.data.watchTheTime ? res.data
													.watchTheTime : '',
												data: it
											}
										})
									} else {
										this.$message.error(res.message)
									}
								})
							} else {
								this.$message.error('当前没有章节信息，请联系管理员上传章节')
							}
						} else {
							this.$message.error(res.message)
						}
					})
				}
			},
			//获取当前时间
			getNowFormatDate() { //获取当前时间
				var date = new Date().getTime();
				return this.nowdate = date;
			},
			//已结束
			end() {
				this.$message('该课程已结束！')
			},
			// 获取列表
			getList() {
				let data = {
					pageSize: this.pageSize,
					pageNumber: this.pageNumber,
					id: this.courseId,
					year: this.yearVal
				}
				const loading = this.$loading({
					lock: true,
					text: "Loading",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});

				this.$http.post('getOrgCourse', data).then(res => {
					if (res.code === 200) {
						res.data.records.forEach(item => {
							let b = item.studyEndTime.split('-')
							let [a, c, d] = b
							let datas = a + '.' + c + '.' + d
							item.studyEndTime = new Date(datas).getTime()

						});
						this.courseList = res.data.records
						
						this.total = res.data.total
					} else {
						this.courseList = [];
						this.total = 0;
						this.$message.error(res.message);
					}
					loading.close();
				}).catch((e) => {
					loading.close();
				});
			},
			// 获取课程年份
			getOrgCourseYears(id) {
				if(!id) return
				this.$http.post('getYearsByClassifyOne',{id}).then(res => {
					if (res.code == 200) {
						this.yearsList = res.data;
						this.yearVal = res.data[0];
						this._getLastLevelClass('two')
					}
				})
			},
			// 获取第一级分类
			_getFirstLevelClass(){
			this.$http.post('course').then(res => {
				if (res.code === 200) {
					this.courseLevel1List = res.data || []
					if(res.data && res.data.length > 0){
						return res.data[0].id
					}else{
						return false
					}
				}else{
					return false
				}
				
			}).then(id=>{
				this.getOrgCourseYears(id)
			})
			},
			// 获取二三级课程分类
			_getLastLevelClass(type){
				let classifyId = 0;
				switch (type) {
					case 'two':
					classifyId = 	this.courseLevel1List[this.oneIndex].id
						break;
					case 'three':
						classifyId = this.courseLevel2List[this.twoIndex].id 
						break;
				}
				let data = {
					classifyId,
					year:this.yearVal
				}
				if(classifyId === 0){
					this.courseId = this.courseLevel1List[this.oneIndex].id
					this.getList()
					 return
				}
				this.$http.post('getClassifyByOrgIdAndYear',data).then(res=>{
					if(res.code === 200){
						if(type === 'two'){
							this.threeIndex = 0
							this.twoIndex = 0
							if(res.data && res.data.length > 0){ //存在二级分类
							this.courseLevel2List = res.data
							this.courseLevel3List = res.data[0].children
							if(res.data[0].children.length > 0){ //存在第三级
							if(this.courseLevel3List[0].id !== 0)	this.courseLevel3List.unshift({name:'全部课程',id:0})
								this.courseId = this.courseLevel2List[this.twoIndex].id
							}else{
							if(this.courseLevel2List[0].id !== 0)	this.courseLevel2List.unshift({name:'全部课程',id:0})
								this.courseId = this.courseLevel1List[this.oneIndex].id
							}
						}else{//不存在二级分类
							this.courseLevel2List = []
							this.courseLevel3List = []
							this.courseId = this.courseLevel1List[this.oneIndex].id
						}
						}else if(type === 'three'){
							if(res.data && res.data.length > 0){//存在三级
								this.courseLevel3List = res.data
								if(this.courseLevel3List[0].id !== 0) this.courseLevel3List.unshift({name:'全部课程',id:0})
								this.courseId = this.courseLevel2List[this.twoIndex].id 
							}else{
								this.courseLevel3List = []
							 if(this.courseLevel2List[0].id !== 0)	this.courseLevel2List.unshift({name:'全部课程',id:0})
								this.courseId = this.courseLevel2List[this.twoIndex].id || this.courseLevel1List[this.oneIndex].id
							}
						}
						this.getList()
					}
				})
			},
			//分页
			handleCurrentChange(e) {
				this.pageNumber = e;
				this.getList()
			},
		
			toCourseDetail(it) {
				if (this.isLogin) {
					this.$router.push({
						path: '/trainingProgram/courseDetails',
						query: {
							id: it.id
						}
					})
				} else {
					this.$message.error('请先登录')
				}

			},
			//立即购买
			toPayEvt(e) {
				// 保存id，防止刷新
				localStorage.setItem('cousreId', e.id)
				if (this.isLogin) {
					this.$router.push({
						path: '/trainingProgram/paymentOrder',
						query: {
							index: 3,
							type: 2,
							data: e
						}
					})
				} else {
					this.$message.error('请先登录')
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.course_level_one {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 30px 0;

		.item {
			display: flex;
			align-items: center;
			white-space: nowrap;
			font-size: 18px;
			color: #666666;
			// margin-right: 37px;
			// 	margin-bottom:19px;
			padding: 10px 20px;
			cursor: pointer;
		}

		.active {
			color: #E51E0B;
			background: #FFFFFF;
			// span{
			// 	color: #E51E0B;
			// }
		}
	}

	.course_level_two {
		background: #fff;
		padding: 28px 30px 0;
		display: flex;
		flex-wrap: wrap;

		.item {
			font-size: 18px;
			color: #666666;
			margin-right: 55px;
			margin-bottom: 28px;
			cursor: pointer;
		}

		.active {
			color: #E51E0B;
		}
	}

	.course_level_three {
		margin-top: 10px;
		background: #fff;
		padding: 20px 30px 0;
		display: flex;
		flex-wrap: wrap;

		.item {
			font-size: 18px;
			color: #666666;
			margin-right: 55px;
			margin-bottom: 20px;
			cursor: pointer;
		}

		.active {
			color: #E51E0B;
		}
	}

	.years {
		margin: 10px 0;
		background: #fff;
		padding: 20px 30px 0;
		display: flex;
		flex-wrap: wrap;

		.item {
			font-size: 18px;
			color: #666666;
			margin-right: 55px;
			margin-bottom: 20px;
			cursor: pointer;
		}

		.active {
			color: #E51E0B;

		}
	}

	.pageContainer {
		background: #eee;
	}

	.main {
		width: 1200px;
		margin: auto;
		flex: 1 1 auto;
	}

	/deep/.el-carousel__button {
		background: #E51E0B;
	}

	/deep/.el-dropdown {
		width: 167px;
		height: 62px;
		text-align: center;
		line-height: 62px;
		cursor: pointer;
	}

	/deep/.el-dropdown:hover {
		background: #fff;
		color: #E51E0B;
	}

	/deep/.el-dropdown-menu {
		width: 1410px;
		display: flex;
	}

	.contentTop {
		.one {
			height: 60px;
			display: flex;
			// width: 1414px;
			overflow-x: auto;

			.oneHeader {
				white-space: nowrap;
				float: left;
				padding: 10px;
			}
		}

		.showStyle {
			width: 100%;
			height: 122px;
			background: #FFFFFF;
			font-size: 18px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 20px;
			padding: 0 20px;

			.twoList {
				padding-top: 5px;
				line-height: 40px;

				.oneStyle {
					position: relative;
					float: left;

					.twoListChild {
						position: absolute;
						top: 40px;
						left: -20px;
						background-color: #fff;
						z-index: 11111;
						width: 400px;
						box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.09);

						.twoList_container {
							float: left;
							padding: 10px;
						}
					}
				}
			}
		}
	}

	.liActive {
		background: #fff;
		color: #E51E0B;
	}

	.contentCenter {
		margin-top: 42px;
		display: flex;
		flex-wrap: wrap;
		// margin: 0 42px;
		padding: 0 58px;
		// justify-content: space-around;

		.cardCls {
			width: 23.6%;
			background: #FFFFFF;
			margin-bottom: 30px;
			margin-right: 20px;

			&:nth-child(4n) {
				margin-right: 0;
			}

			img {
				width: 100%;
				height: 168px;
				cursor: pointer;
			}

			div:nth-child(2) {
				border-bottom: 1px solid #eee;
				padding: 12px;
					p {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #999999;
				}
				.teacherName {
					font-size: 20px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #333333;
					margin: 0;
				}
				.cost{
					font-family: PingFang SC;
					font-weight: bold;
					font-size: 20px;
					color: #E51E0B;
					text-align: right;
				}
			}

			.cardBottom {
				display: flex;
				padding: 12px;
				justify-content: space-between;

				#end {
					color: #FFF;
					background: #ccc;
					border: none;
				}

				div {
					// width: 105px;
					height: 31px;
					border: 1px solid #E51E0B;
					font-size: 12px;
					font-family: PingFang SC;
					font-weight: 600;
					color: #E51E0B;
					text-align: center;
					line-height: 31px;
					cursor: pointer;
					padding: 0 15px;

					&:nth-child(2) {
						color: #fff;
						background: #E51E0B;
						// line-height: 5px;
					}
				}
			}



		}
	}

	/deep/.el-pagination {
		text-align: center;
		margin: 30px 0;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #E51E0B;
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}

	.noData {
		padding-top: 5px;
		width: 120px;
		height: 50px;
		font-size: 16px;
	}

	.teacherMsg {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
</style>
