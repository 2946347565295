import axios from 'axios'
import {
  Message
} from 'element-ui'
import {
  getToken,
  removeToken
} from '@/utils/auth'
import store from '../store'
axios.defaults.timeout = 10000 // 超时时间
let isToast = true

// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做某事
    // config.headers['token'] = getToken() ? getToken() : "" // 让每个请求携带自定义token 请根据实际情况自行修改
    // return config
    // config.headers['domainName'] = window.location.origin;
    // console.log(window.location.origin);
    // config.headers['domainName'] = "test.jjyxt.cn"
    if (localStorage.getItem('token')) {
      config.headers['token'] = localStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
      config.headers['token'] = ''
    }
    return config
  },
  function(error) {
    // 请求错误时做些事
    return Promise.reject(error)
  })

// 返回拦截器
axios.interceptors.response.use(
  function(response) {
    if (response.data.code == '401') {
      Message.warning(response.data.message)
      // removeToken()
      // window.location.href = '/' 
      store.dispatch('LoginOut')
      return Promise.reject(error)
    }
    if (response.data.code == '403') {
      Message.warning('未授权')
      return Promise.reject(error)
    }
    // console.log(response.data.data,'响应')
    // if((!response.data.data)){
    // 	let hideToast = document.getElementsByClassName('el-loading-mask')[0]
    // 	hideToast.style.cssText =" display: none";
    // }
    return response
  },

  function(err) {
    const hideToast = document.getElementsByClassName('el-loading-mask')[0]
    hideToast.style.cssText = ' display: none'
    if (isToast) {
      // Message.warning('服务器异常')
      isToast = false
      return Promise.reject(err)
    }
  }
)